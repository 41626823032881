.navbar {
    height: 80px;
    width: 100%;
    background-color: #E3350D;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    justify-self: flex-start;
}

.navbar ul {
    list-style: none;
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
    font-size: large;
}

.navbar li {
    padding: 16px;
    transition: 0.25s;
    border-radius: 10px;
    color: black;
}

.navbar li:hover {
    color: white;
    transition: 0.25s;
    cursor: pointer;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .navbar,
    .navbar li {
        font-size: 15px;
        padding: 8px;
    }
}