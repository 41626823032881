.modal {
    position: fixed;
    top: 30vh;
    left: 33%;
    width: 33%;
    z-index: 100;
    overflow: hidden;
    background-color: #E3350D;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 32px;
    color: white;
}

.header {
    width: 100%;
    padding: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: black;
}

.content {
    display: flex;
    gap: 1rem;
    padding: 16px;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.col1 {
    text-align: right;
}

.col1 p,
.col2 p {
    padding: 8px;
    text-transform: capitalize;
}

.x-btn-close {
    align-self: flex-end;
    background-color: #E3350D;
}

.x-btn-close:hover {
    cursor: pointer;
}

.submit-or-login {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .modal {
        left: 10%;
        width: 80%;
    }
}