.button {
    width: 120px;
    height: 40px;
    background-color: #E3350D;
    color: #fff;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.button:hover {
    background-color: black;
    transform: scale(1.05);
}

.button img {
    width: 25px;
    object-fit: fill;
}