.game-settings {
  margin: 0;
  background-color: #E3350D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  gap: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.game-settings input,
select {
  width: 250px;
  height: 40px;
  border: none;
  background-color: #fff;
  border-radius: 4px;
  padding: 6px;
  color: #333;
}

.game-settings input:focus {
  outline: none;
  border: 2px solid #E3350D;
}

.game-settings button {
  width: 120px;
  height: 40px;
  background-color: #E3350D;
  color: #fff;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.game-settings button:hover {
  background-color: black;
  transform: scale(1.05);
}