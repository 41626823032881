.card-container {
    margin: .25rem;
    width: 100px;
    height: 175px;
    background-color: transparent;
}

.card-content {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: .5s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.card-rotate .card-content {
    transform: rotateY(180deg);
}

.card-content img {
    width: 75%;
    height: 75%;
}

.card-content h1 {
    font-size: .75rem;
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    border: 1px solid #3E7EA7;
}

.card-front {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    transform: rotateY(180deg);
}

.card-back {
    background: linear-gradient(red, #d66c6c, black);
}

.hide-after-delay {
    animation: hideElement 0.5s forwards;
}

@keyframes hideElement {
    to {
        opacity: 0;
        visibility: none;
        /* For Hard difficuly maybe change display to none */
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .card-container {
        width: 4rem;
        height: 6rem;
        margin: 0.5rem;
    }

    .card-content h1 {
        font-size: .5rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */