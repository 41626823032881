.App {
    height: 100vh;
    text-align: center;
}

.content {
    min-height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

}

.end-game-btn {
    width: 120px;
    height: 40px;
    color: #fff;
    border: none;
    background-color: red;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.end-game-btn:hover {
    background-color: black;
    transform: scale(1.05);
}

.header {
    padding: 16px;
    display: flex;
    justify-content: space-around;
    text-transform: capitalize;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .header {
        font-size: 15px;
    }
}