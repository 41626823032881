.modal {
    position: fixed;
    top: 30vh;
    left: 33%;
    width: 33%;
    z-index: 100;
    overflow: hidden;
    background-color: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.header {
    width: 100%;
    padding: 16px;
    text-align: center;
}

.content {
    padding: 16px;
    width: 100%;
}

.scoreboard-table {
    width: inherit;
    text-align: center;
}

.scoreboard-table td {
    padding: 8px;
}

.loading {
    display: flex;
    justify-content: center;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .modal {
        left: 10%;
        width: 80%;
        font-size: small;
    }

    .header {
        width: 100%;
        padding: 16px;
        text-align: center;
        font-size: small;
    }
}