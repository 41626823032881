.modal {
    position: fixed;
    top: 30vh;
    left: 33%;
    width: 33%;
    z-index: 100;
    overflow: hidden;
    background-color: #E3350D;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.header {
    width: 100%;
    padding: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 16px;
    align-items: center;
}

.content input,
select {
    width: 250px;
    height: 40px;
    border: none;
    background-color: #fff;
    border-radius: 4px;
    padding: 6px;
    color: #333;
}

.content input:focus {
    outline: none;
    border: 2px solid #E3350D;
}

.x-btn-close {
    align-self: flex-end;
    background-color: #E3350D;
}

.x-btn-close:hover {
    cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .modal {
        left: 10%;
        width: 80%;
    }
}