.load-game {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-block;
    border-top: 5px solid red;
    border-right: 5px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.load-game::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border-bottom: 5px solid black;
    border-left: 5px solid transparent;
}


@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}